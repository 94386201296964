<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="accountData.displayName"
              label="Nome"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountData.email"
              label="Email"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountData.fullName"
              label="Nome Completo"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountData.phone"
              label="Contacto"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountData.address"
              label="Morada"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <date-picker
              :label="'Data Nascimento'"
              :date="accountData.birthDate.toString()"
              @date-changed="dateChanged"
            ></date-picker>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountData.gender"
              :rules="[genderRule]"
              label="Sexo (m ou f)"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
              @click="save()"
            >
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { inject } from '@vue/composition-api'
import DatePicker from '../../layouts/components/DatePicker/DatePicker.vue'

export default {
  components: {
    DatePicker,
  },
  props: {
    accountData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    genderRule() {
      return value => value === 'm' || value === 'f' || 'Sexo deve ser "m" ou "f"'
    },
  },
  methods: {
    dateChanged(date) {
      this.accountData.birthDate = date
    },
  },
  setup(props) {
    const userService = inject('userservice')

    const save = () => {
      userService
        .save(props.accountData.id, props.accountData)
        .then(() => alert('Atualizado com sucesso!')) // eslint-disable-line no-alert
    }

    return {
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
      save,
    }
  },
}
</script>
