<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <account-settings-account :account-data="accountSettingData">
        </account-settings-account>
      </v-tab-item>
      <v-tab-item>
        <documents-section
          :person-id="Number.parseInt(personId)"
          :person-display-name="accountSettingData.displayName"
          :files-details="filesDetails"
          @refreshDocuments="getDocuments"
        >
        </documents-section>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
} from '@mdi/js'

import { ref, inject } from '@vue/composition-api'
import AccountSettingsAccount from './AccountSettingsAccount.vue'
import DocumentsSection from '../../layouts/components/files/DocumentsSection.vue'

export default {
  components: {
    AccountSettingsAccount,
    DocumentsSection,
  },
  setup() {
    const tokenUtils = inject('tokenutils')
    const userService = inject('userservice')
    const accountSettingData = ref({})
    const token = tokenUtils.GetToken()
    const personId = tokenUtils.GetClaim(token, 'person_id')
    const tab = ref('')

    // tabs
    const tabs = [
      { id: 'personalDetails', title: 'Dados Pessoais', icon: mdiAccountOutline },
      { id: 'files', title: 'Ficheiros', icon: mdiLockOpenOutline },
    ]
    const filesDetails = ref([])

    const getDocuments = () => {
      userService.getDocuments(personId)
        .then(response => {
          filesDetails.value = response.sort((a, b) => a.documentId - b.documentId)
        })
        .catch(error => {
          console.log(error)
          alert('Something went wrong') // eslint-disable-line no-alert
        })
    }

    userService.get(personId)
      .then(response => {
        accountSettingData.value = response
      })

    getDocuments()

    return {
      getDocuments,
      filesDetails,
      personId,
      tab,
      tabs,
      accountSettingData,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
      },
    }
  },
}
</script>
